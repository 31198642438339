.page .content .serie-header .title {
    display: flex;flex-direction: column;
    justify-content: center; align-items: start;
    background-color: rgb(0, 0, 0);
    padding: 0 10rem 0.5rem;
    padding-top: 2rem;
    margin: 0;
}
.page .content .serie-header .title p {
    background-color: black; color: white;
}
.page .content .serie-header .title h1 {
    width: 100%;
    color: rgb(255, 255, 255);
    font-size: 64px;
    line-height: 0.8;
    font-family: 'Montserrat', serif;
    font-weight: 400;

}
.page .content .serie-header .description {
    font-size: 22px;
    font-weight: 300;

    padding: 2rem 10rem ;
    text-align: left;
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    line-height: .9;
    margin-bottom: 1rem;
}

@media only screen and (max-width:500px) {
    .page .content .serie-header .title h1 {
        font-size: 42px;
    }
    .page .content .serie-header .title{
        padding: 1rem;
    }
    .page .content .serie-header .description{
        padding: 1rem;
        font-size: 24px;
    }
}