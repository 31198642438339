.login-form-container {
    width: 100%;height: 100vh;
    display: flex; justify-content: center;align-items: center;
    flex-direction: column;
    color: white;
    background-color: black;
}
.login-form-container h2 {
    font-size: 22px;
}
.login-form-container .login-form{
    text-align: center;
    margin-top: 1rem;
}
.login-form-container .login-form button{
    background-color: white;
    color: black;
    border-radius: .5rem;
    border: none;
    padding: 0.5rem 1rem;
    margin-top: 1rem;
}
.login-form-container .login-form .input-label {
    display: flex;flex-direction: column;
    margin-top: .5rem;
}
.login-form-container .login-form .input-label input{
    border-radius: .5rem;
    text-align: center;
}
