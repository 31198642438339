.addPaint-form{
    color: black;
    text-align: center;
    
}
.addPaint-form p {
    margin: 0;
    font-size: 22px;
}
.addPaint-form .inputs-container {
    display: flex;
}
.addPaint-form .inputs-container:nth-child(0){
    width: 20rem;
}
.addPaint-form .inputs-container .column .label {
    display: flex; flex-direction: column;
    margin-bottom: .5rem;
    color: black;
}
.addPaint-form .inputs-container .column .label input {
    text-align: center;
    background-color: black !important;
    color: white!important;
    height: 2rem;
    border-radius: .5rem;
}
.addPaint-form .inputs-container .column .label textarea {
    text-align: center;
    background-color: black;
    color: white;
    height: 4rem;
    border-radius: .5rem;
}

.addPaint-form .inputs-container .column .label input button {
    background-color: black;
}
.addPaint-form .inputs-container .column .label select {
    text-align: center;
    background-color: black;
    color: white;
    height: 2rem;
    border-radius: .5rem;
}
.addPaint-form .btn-container {
    width: 100%;
    display: flex;justify-content: end;
}
.addPaint-form .btn-container button {
    padding: .5rem 1rem;
    background-color: black;
    color: white;
    border-radius: .5rem;
}