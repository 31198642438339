.buy-info h3 {
    font-family: 'Montserrat', serif;
    font-size: 32px;
    font-weight: 500;
    text-align: center;
    color: black;
    padding: 1rem 0.5rem 0.5rem;
    width: 100%;
    margin: 2rem 0;
    letter-spacing: 0.8px;
    line-height: 1;
}
.buy-info .items-info {
    margin: 0.5rem;
    display: flex; flex-direction: column;
}
.buy-info .items-info .item {
    display: flex; justify-content: center; align-items: center;
    margin-bottom: 0.5rem;
    background-color: black;
    padding: 2rem 0.5rem;
}
.buy-info .items-info .item .icon {
    color: white;
    font-size: 3rem;
    margin: 0.5rem;
}
.buy-info .items-info .item p {
    color: white;
    margin: 0.5rem;
    margin-top: 0;
    font-family: 'Montserrat';
    font-size: 20px;
    font-weight: 200;
    line-height: 1.1;
}

@media only screen and (min-width:770px){

    .buy-info .items-info {
        flex-direction:row;
    }
}