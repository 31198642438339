.serie-card .content{
    height: 100%;
    display: flex;
    align-items:center;
    justify-content: space-around;
    
}

.serie-card .content .col {
    display: flex; justify-content: center; align-items: center;
    height: 100%;width: 100%;
}   
.serie-card .content .item-info .cat{
    width: 100%;
    padding-left: 1rem;
    color: black;
    font-size: 20px;
    
}
.serie-card .content .col .img-container{
    height: 100%;
    display: flex; justify-content: center; align-items: center;

}
.serie-card .content .col .img-container img{ 
    object-fit:contain;
    max-height: 25rem;
    width: 100%;

}

.serie-card .content .item-info {
    width: 60%;
    text-align: center;
    padding-bottom: 0; margin-bottom: 0;
}
.serie-card .content .item-info {
    text-align: left;
    
}

.serie-card .content .item-info .title h3 {
    color: rgb(255, 255, 255);
    font-size: 45px;
    line-height: 0.8;
    font-family: 'Montserrat', serif;
    font-weight: 400;
    background-color: rgb(0, 0, 0);
    padding: 0.5rem;
    margin: 0;
}
.serie-card .content .item-info .description {
    font-size: 22px;
    font-weight: 300;
    padding: 0.5rem;
    padding-right: 2rem;
    text-align: left;
  
    color: rgb(0, 0, 0);
    line-height: .9;
    margin-bottom: 1rem;
}
.serie-card .content .item-info .btn-container{
    width: 100%;
    display: flex; justify-content: left;
    padding-right: 5rem;
}
.serie-card .content .item-info .btn-container .btn {
    font-size: 28px;
    font-weight: 300;
    color: black;
    padding: 0 1rem ;
    
    border: 1px solid black;
    
}

.carousel-control-prev {
    width: auto;
}
.carousel-control-next {
    width: auto;
}

@media only screen and (max-width: 900px) {
    .serie-card {
     
        margin-bottom: 3rem;
        
    }
    .serie-card .content{
        
        justify-content: center;
        flex-direction: column;
       
    }
    .serie-card .content .col .img-container {
        min-height: 60vh;
        margin: 0 0.5rem;
        margin-bottom: 1rem;
        display: flex;justify-content: center;align-items: center;
    }
    .serie-card .content .col .cat{
        width: 100%;
        margin-top: 2rem;
        padding-left: 1rem;
        color: white;
        font-size: 20px;
        letter-spacing: 1px;

    }
    .serie-card .content .col .img-container img {
        width: 100%;
        max-height: 100%;
        margin: 0.5rem;
        cursor: pointer;
        
    }
    @media only screen and (max-width: 365px){
        .serie-card {
            height: 100vh;
            padding-top: 3rem;
        }
    }
    @media only screen and (min-width: 768px) {
        .serie-card{
            margin-top: 1rem;
        }
        .serie-card .content{
            height: 100%;
        }
        
        .serie-card .content .col .img-container{
            width: 55vh;
            min-height: 55vh;
            padding: 1rem;
 
        }
        .serie-card .content .col .cat{
            margin-top: 2rem;
        }
        .serie-card .content .col .img-container img {
            margin: 1rem 0;
            object-fit: contain;
        }
    }
    .serie-card .content .item-info {
        width: 100%;
        min-height: 40vh;
    }
    .serie-card .content .item-info .btn-container {
        justify-content: right;
        padding: 0.5rem;
    }
    .serie-card .content .item-info .btn-container .btn {
        width: 100%;
        padding: 1rem;
    }
}

@media only screen and (min-width: 900px) {
    .serie-card {
        height: 90vh;
        margin-bottom: 2rem;
    }
    .serie-card .content  {

        justify-content: center; align-items: center;

    }
    .serie-card .content .col {
        width: 50%;
    }
    .serie-card .content .item-info {
        width: 50%;
        height: 100vh;
        display: flex; flex-direction: column;
        justify-content: center;
        margin-left: 2rem;
    }

}