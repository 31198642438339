.contact {
    margin: 0;
    margin-bottom: 1rem;
    
}
.contact .contact-header  {
    display: none;
    height: 35vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.contact h2 {
    font-size: 28px;
    padding: 0 1.5rem;
}
.contact-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 400px;
    margin: 2rem auto;
    padding: 1rem;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
}

.contact-form label {
    display: flex;
    flex-direction: column;
    font-weight: bold;
}

.contact-form input,
.contact-form textarea {
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: 0.5rem;
}

.contact-form button {
    padding: 0.75rem;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    font-size: 1rem;
    cursor: pointer;
    margin-top: 1rem;
}

.contact-form button:hover {
    background-color: #0056b3;
}

.contact-form p {
    font-weight: bold;
    color: green;
}
.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

@media only screen and (min-width:900px){
    .contact {
        display: flex;
    }
    .contact .contact-header {
        display: none;
    }
    .contact-form{
        width: 50%;
    }
    
}