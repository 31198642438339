.paints-list-container {
    display: flex; flex-wrap: wrap;
    align-items: center; justify-content: start;
    padding: 1rem;
    padding-top: 0;
}


@media only screen and (max-width:500px) {
   
    
}
