.paint-container{
    width: 100%;
    margin: 5rem 0;
    height: 100vh;
    display: flex;
    align-items: center;
    margin-top: 5rem;
    padding: 4rem;
    padding-top: 1rem;
}
.paint-container .paint-header {
    
 
    padding: 0 10rem 0.5rem;
    padding-top: 2rem;
    margin: 0;
}

.paint-container .paint-body {
    width: 100%;
    
    display: flex;
    justify-content:center; align-items: center;
}
.paint-container .paint-body .carousel-container {
    width: 50%;
    max-height: 100vw;
    padding: 4rem;
    
}
.paint-container .paint-body .carousel-container button {
    text-align: right;
    width: 100%;
    border: none;
    color:rgb(99, 127, 189);
}
.paint-container .paint-body .img-visualize{
   
    height: 100%;
}
.paint-container .paint-body .img-visualize .carousel-inner .carousel-item{
    width: 100%;
    height: 100%;
    text-align: center;
    align-items: center;
}   
.paint-container .paint-body .img-visualize .carousel-inner .img {
    cursor:zoom-in;
    max-width: 100%;
    margin-left: auto; margin-right: auto;
 
}

.paint-container .paint-body .info {
    width: 50%;
    margin-left: 0.5rem;
}
.paint-container .paint-body .info .body {
    font-size: 20px;
    font-weight: 400;

    padding: 1rem 0.5rem;
    padding-right: 2rem;
    text-align: left;

    color: rgb(0, 0, 0);
    line-height: .9;
    
}
.paint-container .paint-body .info .head {
    
    padding: 0.5rem;
    color: black;


}
.paint-container .paint-body .info  .head h2 {
    font-size: 1.5rem;
    font-weight: 300;
    font-size: 20px;
    margin: 0;
    color: black;
    padding: 0.5rem 1rem 0.5rem 0;
    width: auto;
}
.paint-container .paint-body .info  .head .title {
    display: flex; align-items: center;
}
.paint-container .paint-body .info  .head .title h1{

    font-size: 42px;
    line-height: 0.8;
    font-family: 'Montserrat', serif;
    font-weight: 400;
    margin: 0;
}
.paint-container .paint-body .info  .head .title .circle {
    margin-left: 1rem;
    width: 1.5rem; height: 1.5rem;
    background-color: rgb(167, 28, 28);
    border-radius: 100%;
}
.paint-container .paint-body .info button {
    font-size: 24px;
    font-weight: 300;
    color: black;
    padding: 0.5 rem 1rem;
    margin: 1rem;
    border: 1px solid black;
}
.paint-container .paint-body .info button:hover {
    background-color: rgba(0, 0, 0, 0.724);
}
.paint-container .paint-body .info .body .detail{
    
}

.paint-container .paint-body .info .body .detail .detail-item{
    display: flex;
    margin-bottom: 0.5rem;
    margin-top: .5rem;
}
.paint-container .paint-body .info .body .detail .detail-item p{
    font-size: 18px;
    margin: 0;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    font-weight: 500;
}
@media only screen and (max-width:900px){
    .paint-container .paint-body {
        flex-direction: column;
    }
    .paint-container .paint-body .carousel-container {
        width: 100%;
    }
    .paint-container .paint-body .carousel-container button {
        margin-top: 2rem;
    }
    .paint-container .paint-body .info {
        width: 100%;
        
        padding: 0 5rem ;
    }
    .paint-container .paint-body .info .head h2 {
        font-size: 24px;
    }
    .paint-container .paint-body .info .head .title h1 {
        font-size: 48px;
    }
    .paint-container .paint-body .info .body .detail .detail-item p  {
        font-size: 24px;
    }
    #root > div > div.page > div.paint-container > div > div.info > div.body > div.price > p {
        font-size: 28px;
    }
    .paint-container .paint-body .info button {
        width: 100%;
        font-size: 28px;
    }
}
@media only screen and (max-width:500px){
    .paint-container{
        margin-bottom: 8rem;
        padding: 1rem;
    }
    .paint-container .paint-body{
        flex-direction: column;
      
    }
    .paint-container .paint-body .img-visualize {
        width: 100%; height: 100vw;
        margin-bottom: 1rem;
    }
   
    .paint-container .paint-body .carousel-container {

        padding: 1rem;
        
    }
    .paint-container .paint-body .img-visualize .carousel-inner{
        display: flex; align-items: center; justify-content: center;
    }
    .paint-container .paint-body .img-visualize .carousel-inner .img {
        max-width: 100%;
        margin-left: auto;margin-right: auto;
        margin-top: 1rem;
    }

    .paint-container .paint-body .img-visualize .carousel-inner {
        display: block;
    }


    .paint-container .paint-body .info {
        width: 100%;
        padding: 1rem 0.5rem; margin: 0;
        margin-top: 1rem;
    }

    .paint-container .paint-body .info .body .price p{
        font-size: 24px;
    }

    .paint-container .paint-body .info button {
        width: 100%;
        margin: 0;
        padding: 0.5rem;
    }
    .paint-container .paint-body .info  .head .title {
        justify-content: left;
    }
    .paint-container .paint-body .info  .head .title .circle {
        margin: 0 0 0 1rem;
        width:2rem; height: 2rem;
        background-color: rgb(167, 28, 28);
        border-radius: 100%;
    }
    .paint-container .paint-body .info .head h2 {
        font-size: 20px;
    }
    .paint-container .paint-body .info .head .title h1 {
        font-size: 36px;
    }
    .paint-container .paint-body .info .body .detail .detail-item p  {
        font-size: 18px;
    }
    #root > div > div.page > div.paint-container > div > div.info > div.body > div.price > p {
        font-size: 28px;
    }
    .paint-container .paint-body .info button {
        width: 100%;
        font-size: 22px;
    }
}
