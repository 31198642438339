.paints-slider {
    font-family: 'Lora', serif;
    display: flex;flex-direction: column;
    align-items: center;justify-content: center;
    padding: 2rem 1rem;
    overflow: hidden;
}
.paints-slider .slider {
    
width: 90%;
}
.paints-slider h2 {
    font-size: 45px;
    font-weight: 400;
    background-color: rgb(255, 255, 255);
    color: white;
    padding: 1rem 0.5rem 0.5rem;
    width: 100%;
    margin: 0;
    letter-spacing: 1px;
}

.slick-prev {
    
    font-size: 5rem;
}
.slick-next {
    right: 0px;
    font-size: 5rem;
    margin-left: 2rem;
}

@media only screen and (max-width: 768px){
    .paints-slider{
        margin: 0;
        padding: 0;
    }
}