.list {
    display: flex; flex-wrap: wrap;

    padding: 1rem;
    overflow-y: scroll;
    height: 75vh;
}
.list .card-panel {
    background-color: rgba(240, 248, 255, 0);
    margin: 0.5rem;
    width: 18rem; height: 22rem;
    background-color: rgb(33, 33, 33);
    color: white;
    border-radius: 0.5rem;
    
    text-align: center;
}

.list .card-panel .header {
    display: flex; justify-content: end; align-items: center;
    padding: 0.5rem;
}
.list .card-panel .header .icon {
    margin-left: 0.5rem;
    cursor: pointer;
}

.list .card-panel .img {
    width: 100%;height: 13rem;
    display: flex; justify-content: center; align-items: center;
}
.list .card-panel .img img {
    object-fit: contain;
    max-height: 13rem; max-width: 13rem;
}

.list .card-panel .body {

    padding-top: 0.5rem;
}
.list .card-panel .body P {
    margin: 0;
}
.list .card-panel .body .detail {
    
}
.list .card-panel .body .detail p {
    margin: 0;
    font-size: 12px;
    letter-spacing: .8px;
}