.image-list {
    display: flex; justify-content: row;
    overflow-y: auto;
    gap: 10px;
    padding: 0.5rem;
    max-width: 25rem;
  }
  
  .image-container {
    position: relative;
    width: 8rem; height: 8rem;
  }
  
  .image {
    max-width: 150px;
    max-height: 150px;
    border-radius: 5px;
    margin-right: 0.2rem;
  }
  
  .delete-button {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: transparent;
    border: none;
    color: rgb(0, 0, 0);
    font-size: 1.5rem;
    cursor: pointer;
  }
  