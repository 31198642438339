.bio-container {

}
.bio-container .bio-header {
    width: 100%;
}
.bio-container .bio-header .header-title {
    
    padding: 0.5rem;
}
.bio-container .bio-header .header-title p {
    margin: 0;
}
.bio-container .bio-header .img {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 50vh;
}

.bio-container .bio-body .title{
    padding: 0.5rem;
    margin-top: 1rem;
}
.bio-container .bio-body .title h2 {
    font-size: 28px;
    padding: 0 1.5rem;
}
.bio-container .bio-body p {
    font-size: 20px;
    padding: 0.5rem 1.5rem;
    line-height: 1.2;
    
    text-align: justify;
}
.bio-container .bio-items h4{
    padding: 0 1.5rem;
}
.bio-container .bio-items p {
    font-size: 18px;
    font-weight: 500;
}

@media only screen and (min-width: 900px) {
    .bio-container .bio-header .header-title {
    
        padding: 1rem;
    }
    .bio-container .bio-body {
        padding: 1rem 10rem;
    }
}