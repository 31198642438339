.paint-card {
    display: flex;flex-direction: column;
    margin-left: auto; margin-right: auto;
    cursor: pointer;
    align-items: center;justify-content: center;
   
    background-color: white;
    margin-bottom: 2rem;
}
.slick-slider {
    display: flex; justify-content: center; align-items: center;
}
.paint-card .img-container {
     height: 20rem; width: 20rem;
     max-width: 100%;
     background-size: contain;
     background-repeat: no-repeat;
     background-position: center;
}

.paint-card .content {
    color: white;

}
.paint-card .content .title h3 {
    font-family: 'Montserrat', serif;
    font-size: 22px;
  
    margin-top: 1rem;
    color: black;
    display: flex;justify-content: center; align-items: center;
}
.paint-card .content .title .detail {
    display: flex;flex-direction: column;
    justify-content: center;
    text-align: center;
    width: 100%;
  
}
.paint-card .content .title .detail p {
    
    font-family: 'Montserrat';
    font-weight: 300;
    line-height: 1;
    font-size: 16px;
    color: rgb(0, 0, 0);
}
.paint-card .content .description h3 {
    font-family: "Montserrat";
    font-size: 24px;
    letter-spacing: 1px;
    font-weight: 100;
    line-height: .8;
    color: rgb(255, 255, 255);
}
.paint-card .content .link {
    font-size: 22px;
    font-weight: 200;
    text-decoration: line;
    color: rgb(72, 85, 92);
    padding: 0 1rem;
    border: none;
    background-color: white;
    border-radius: 0.2rem;
}

.paint-card .content {
    text-align: center;
}

.paint-card .content .description h3 {
    padding-top: 1rem;
    height: 5rem;
}
.paint-card .content .link {
    justify-content: center;
}

.paint-card .card-footer {
   
}
.paint-card .card-footer {
    display:flex;
    align-items: center;
    justify-content: center;
    background-color:rgba(255, 255, 255, 0);
    width:100%;

}
.paint-card .card-footer .footer-item p {
    height: 3rem;
    font-family: 'Montserrat';
    font-size: 16px;
    font-weight: 400;
    margin: 0.5rem;
}
#root > div > div.home.page > div.paints-slider > div > ul > li.slick-active > button{
    color: white;
}

@media only screen and (min-width:768px){
    .paint-card {
    
        width: 10rem;
        margin: 1rem 0;
        padding: 1rem;
        justify-content: baseline;
        transition: transform 0.5s ease;
    }
    .paint-card:hover {
        transform: translateY(-5px) scale(1.05); /* Desplazar hacia arriba y escalar */
    }
}
@media only screen and (min-width:901px){
    .paint-card:hover {
        transform: translateY(-5px) scale(1.05); /* Desplazar hacia arriba y escalar */
      }
}