.cart-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    right: 0;
    z-index: 1000;
    background-color: rgb(17, 17, 17);
    font-family: 'Montserrat';
    color: rgb(255, 255, 255);
}

.cart-container .cart-header p:nth-child(1){
    margin-top: 2rem;
    font-size: 30px;
    text-align: center;
}
.cart-container .cart-header p:nth-child(2){
    font-size: 20px;
    padding: 1rem;
}

.cart-container .cart-items {
    width: 100%;
    display: flex; flex-direction: column;
    max-height: 50vh;
    overflow-y: scroll;
    letter-spacing: 0.5px;
    

}
.cart-container .cart-items::-webkit-scrollbar {
    width: 5px;
    color: aliceblue; /* Ancho de la barra de desplazamiento */
}
.cart-container .cart-items::-webkit-scrollbar-thumb{
    background-color: rgb(36, 36, 36);
}
.cart-container .cart-items .cart-item {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: rgb(0, 0, 0);
    border-radius: 0.5rem;
    width: 100%;
    margin-top:.5rem;
}
.cart-container .cart-items .cart-item .item-img {
    padding: 0.5rem;
    width: 50%;
}
.cart-container .cart-items .cart-item .item-img img {
    width: 100%; 
}
.cart-container .cart-items .cart-item .item-body{
    padding: 1.5rem 0.5rem;
}
.cart-container .cart-items .cart-item .item-body .body-detail {
    display: flex;
    justify-content: space-between;
    margin:  0 0 1rem 0;
    
}
.cart-container .cart-items .cart-item .item-body .body-detail p {
    margin:0;
}
.cart-container .cart-items .cart-item .item-body .body-detail p:nth-child(1){
    font-size: 22px;
    font-weight: 500;
    line-height: 1;
    margin-bottom: .5rem;
}

.cart-container .cart-items .cart-item .item-body .body-detail svg {
    margin-left: 0.5rem;
    font-size: 30px;
    cursor: pointer;
}


.cart-container .cart-items .cart-item .item-body .body-controls .amount-control {
    display: flex;
}
.cart-container .cart-items .cart-item .item-body .body-controls .amount-control input {
    width: 3rem;
    text-align: center;
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    border-radius: 5rem;
}
.cart-container .cart-items .cart-item .item-body .body-controls .amount-control button {
    color: rgb(255, 255, 255);
    font-size: 22px;
    background-color: rgb(33, 33, 33);
    width: 2rem; height: 2rem;
    display: flex; justify-content: center; align-items: center;
    border: none;
    border-radius: 5rem;
    margin: 0 0.5rem;
}

.cart-container div  .cart-total {
    width: 100%;
    display: flex;
    align-items: center; justify-content: center;
    padding: 0.5rem;

}

.cart-container .cart-footer {
    width: 100%;
}
.cart-container .cart-footer  .cart-total p {
    margin: 0;
    font-size: 22px;
    font-weight: 300;

    letter-spacing: 1.2px;
}
.cart-container .cart-footer .button-container{
    width: 100%;
    display: flex;
    justify-content: center;
}
.cart-container .cart-footer .button-container button {
    width: 100%;
    font-size: 24px;
    padding: 0.5rem 1rem;
    font-size: 22px;
    border-radius: 0.5rem;
    margin: 1rem;
    margin-bottom: 2rem;
}

@media only screen and (min-width:500px) {

    .cart-container .cart-items{
        align-items: center;
        max-height: 60vh;
    }
    .cart-container .cart-items .cart-item {
        width: 75%;
    }
    .cart-container .cart-items .cart-item .item-img {
        width: 40%;
    }


}
@media only screen and (min-width:900px){
    .cart-container {
        width: 30%;
        height: 100%;
    }
    .cart-container .cart-items {
        height: 100%;
    }
    .cart-container .cart-header p:nth-child(1){
        margin: 0;
        font-size: 24px;
        padding: 1rem;
        font-weight: 300;
    }

    .cart-container .cart-footer .button-container{
        margin-bottom: 4rem;
    }

    .cart-container .cart-items .cart-item {
        width: 98%;
        padding: 0;
        
    }

    .cart-container .cart-items .cart-item .item-body{
        width: 50%;
    }

    .cart-container .cart-items .cart-item .item-body .body-detail p:nth-child(1){
        font-size: 18px;
        font-weight: 300;
    }
    .cart-container .cart-items .cart-item .item-body .body-detail p:nth-child(2){
        font-size: 18px;
        font-weight: 100;
    }
}