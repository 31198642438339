.summary-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Negro transparente */
    z-index: 5000; /* Asegura que esté por encima de todo */
    display: none;
}

.summary-overlay.visible {
    display: block;
}

.summary-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 1000; /* Asegura que esté por encima de la superposición */
    display: none;
}

.summary-container.visible {
    display: block;
}

.summary-container.hidden {
    display: none;
}

.summary-container .summary-header {
    display: flex; align-items: center;justify-content: space-between;
    padding: 1rem;
    padding-bottom: 0;
}
.summary-container .summary-items {
    padding: 1rem;
}
.summary-container .summary-items ul{
    
    margin: 0; padding: 0;
    list-style: none;
}
.summary-container .summary-items ul li span {
    margin-right: 0.5rem;
}
.summary-container .summary-items ul li span:nth-child(1){
    font-weight: 600;
}
.summary-container .summary-items ul li .line {
    width: 100%; height: .2px;
    background-color: rgb(184, 184, 184);
    margin: 0 0.5rem 0.5rem 0;

    
}
.summary-container .summary-items .total-price span:nth-child(1){
    font-weight: 600;
    font-size: 20px;
}
.summary-container .summary-items .total-price span{
    margin-right: 0.5rem;
}
.summary-container .summary-form{
    padding: 1rem;
    text-align: center;
}
.summary-container .summary-form h2 {
    font-size: 24px;
    padding: 0 2rem;
}
.summary-container .summary-form form div{
    display: flex;
    justify-content: center;align-items: center;
}
.summary-container .summary-form form .form-group {
    width: 100%;
    display: flex;flex-direction: column;
    margin-bottom: 0.5rem;
}
.summary-container .summary-form form .form-group input {
    text-align: center;
    width: 100%;
}
.summary-container .summary-form form .form-group textarea{
    text-align: center;
    min-height: 5rem;
    width: 100%;
}
.summary-container .summary-form form button {
    padding: 0.5rem 1rem;
    color: white;
    background-color: black;
    font-size: 22px;
    width: 100%;
    border-radius: 0.5rem;
    display: flex; justify-content: center; align-items: center;
}

@media only screen and (max-width:768px){
    
    .summary-container{
        width: 95%;
    }

    .summary-container .summary-form form .form-group input {
        text-align: center;
        height: 3rem;
    }
    .summary-container .summary-form form .form-group textarea{
        text-align: center;
        min-height: 5rem;
    }
    .summary-container .summary-items ul {
        max-height: 20vh;
        overflow-x: scroll; 
    }
}