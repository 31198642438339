.bio-card {
    margin: 4rem 0;
    font-family: 'Montserrat', serif;
    border: 1px solid rgba(0, 0, 0, 0.332);
    padding: 2rem 1rem;
    margin: 1rem;
    margin-top: 2remrem;
    border-radius: 0.5rem;
}
    
.bio-card .card-container .card-img{
    width: 100%;
    height: 25rem;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 0.5rem;
    
}
.bio-card .card-container .card-body {
 
}
.bio-card .card-container .card-body h3 {
    font-size: 45px;
    font-weight: 400;
   
    color: black;   
    padding: 0.5rem;
    width: 100%;
    margin: 0;
    letter-spacing: 1px;
}
.bio-card .card-container .card-body p {
    font-size: 22px;
    padding: 1rem 0.5rem;
    font-family: 'Montserrat';
    line-height: 0.9; letter-spacing: 1px;
    margin: 0;
}
.bio-card .card-container a {
    display: block;
    width: 100%;
    font-size: 24px;
    padding: 1rem;
    font-family: 'Montserrat';
    margin: 0;
    padding-right : 2rem;

    
}

@media only screen and (min-width:900px){
    .bio-card{
    cursor: pointer;
    transition: background-color, 1s;
}
    .bio-card:hover{
        scale: 101%;
    }
    .bio-card .card-container {
        margin: 0 5rem;
        display: flex;
        flex-direction: row;
        justify-content: center; align-items: center;
        flex-direction: row-reverse;
    }

    .bio-card .card-container .card-body h3 {
        font-size: 42px;
    }
    .bio-card .card-container .card-body p {
        font-size: 22px;
    }
    .bio-card .card-container .card-body a {
        font-size: 28px;
    }
}