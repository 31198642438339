.swich-len{
    width: 100%; height: 100%;
    color: black;
    display: flex; align-items: center; justify-content: center;
}
.swich-len .swich-item{
    color: rgb(70, 70, 70);
    padding: 0.5rem 1rem;
    cursor: pointer;
}
.swich-len .len{
    font-weight: 600;
}

@media only screen and (max-width: 900px){
    .swich-len{
        height: 15%;
    }
}
@media only screen and (min-width: 570px) and (max-width: 900px){
    .swich-len .swich-item {
        font-size: 22px;
    }
}
