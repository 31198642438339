.whatsapp-button {
    position: fixed; /* Fijo para que siempre esté visible */
    bottom: 20px; /* Distancia desde la parte inferior */
    right: 20px; /* Distancia desde el lado derecho */
    background-color: #25D366; /* Color de fondo de WhatsApp */
    border-radius: 50%; /* Forma circular */
    width: 60px; /* Tamaño del botón en pantallas grandes */
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Sombra */
    cursor: pointer; /* Cursor de mano */
    z-index: 1000; /* Asegura que esté por encima de otros elementos */
    transition: transform 0.3s ease; /* Suave animación al hacer hover */
}

.whatsapp-button:hover {
    transform: scale(1.1); /* Aumenta ligeramente el tamaño al pasar el ratón */
}

.whatsapp-icon {
    width: 35px; /* Tamaño del ícono */
    height: 35px;
}

/* Adaptaciones para dispositivos móviles */
@media (max-width: 768px) {
    .whatsapp-button {
        width: 50px; /* Tamaño del botón para tabletas y pantallas medianas */
        height: 50px;
        bottom: 15px; /* Ajusta la posición desde el borde inferior */
        right: 15px; /* Ajusta la posición desde el borde derecho */
    }

    .whatsapp-icon {
        width: 30px; /* Tamaño del ícono en dispositivos medianos */
        height: 30px;
    }
}

@media (max-width: 480px) {
    .whatsapp-button {
   
        bottom: 10px; /* Posiciona más cerca del borde inferior en móviles */
        right: 10px; /* Posiciona más cerca del borde derecho en móviles */
    }

}

