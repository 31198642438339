.banner {

}
.banner .banner-item {
    width: 100%;
    
}


@media only screen and (max-width: 900px) {
    .banner {
        margin-bottom: 2rem;


    }
  
}