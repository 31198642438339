body > div > div > div.react-viewer-canvas > img{

 object-fit: contain !important;   

}

.zoom-controls {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: flex;
    gap: 10px;
  }
  
  .zoom-controls button {
    padding: 10px;
    font-size: 16px;
    cursor: pointer;
  }

  .lightbox-zoom-controls {
    display: none;
  }
  .ril-zoom-in,
  body > div.yarl__root.yarl__portal.yarl__no_scroll_padding.yarl__portal_open > div > div.yarl__toolbar > button:nth-child(1){
  display: none !important;
}
body > div.yarl__root.yarl__portal.yarl__no_scroll_padding.yarl__portal_open > div > div.yarl__toolbar > button:nth-child(2){
    display: none !important;
  }