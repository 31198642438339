.admin-panel {
    color: white;
    background-color: black;
    width: 100%; height: 100vh;
    padding: 1rem;
    
}
.admin-panel h2 {
    font-size: 20px;
}
.admin-panel .content {
    display: flex;
    height: 90vh;
}
.admin-panel .content .sidebar {
    width: 20%;
    padding: 2rem 0rem;
}
.admin-panel .content .sidebar ul{
    padding: 0;
}
.admin-panel .content .sidebar ul .sidebar-item {
    list-style: none;
    font-size: 18px;
    font-weight: 200;
    cursor: pointer;
    padding: 0.5rem;
    border: .1px solid rgba(255, 255, 255, 0.665);
    border-radius: .5rem;
    margin-bottom: 0.5rem;
    text-align: center;
}
.admin-panel .content .sidebar ul .sidebar-item.select {
    font-weight: 400;
}
.admin-panel .content .list-container{
    width: 80%; 
    margin-top: 2rem;
}
.admin-panel .content .list-container .head {
    display: flex;align-items: center;
    margin-left: 2rem;
    height: 10vh;
}
.admin-panel .content .list-container .head button {
    border-radius: .5rem;
    border: 1px solid white;
    padding: 0.5rem 1rem;
    font-size: 15px;
    font-weight: 500;
    margin-left: 2rem;
}

